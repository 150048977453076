// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_OeDFA {
  display: flex;
  justify-content: center;
  border: none;
  padding: 9px 1px;
  border-radius: var(--mds_brand_border_medium_radius);
  font-family: "Maersk Text", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.buttonContainer_xvyEO {
  display: flex;
  padding: 1px 15px;
}

.default_sTEoH {
  background: var(--mds_brand_appearance_primary_default_text-color);
  color: var(--mds_brand_appearance_neutral_inverse_text-color);
}

.error_QNCGU {
  background: rgb(184, 0, 18);
  color: var(--mds_brand_appearance_neutral_inverse_text-color);
}

.disabled_S1kut {
  background-color: rgba(0, 36, 61, 0.5);
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;EAChB,oDAAoD;EACpD,sCAAsC;EACtC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,kEAAkE;EAClE,6DAA6D;AAC/D;;AAEA;EACE,2BAA2B;EAC3B,6DAA6D;AAC/D;;AAEA;EACE,sCAAsC;EACtC,oBAAoB;AACtB","sourcesContent":[".button {\n  display: flex;\n  justify-content: center;\n  border: none;\n  padding: 9px 1px;\n  border-radius: var(--mds_brand_border_medium_radius);\n  font-family: \"Maersk Text\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  cursor: pointer;\n}\n\n.buttonContainer {\n  display: flex;\n  padding: 1px 15px;\n}\n\n.default {\n  background: var(--mds_brand_appearance_primary_default_text-color);\n  color: var(--mds_brand_appearance_neutral_inverse_text-color);\n}\n\n.error {\n  background: rgb(184, 0, 18);\n  color: var(--mds_brand_appearance_neutral_inverse_text-color);\n}\n\n.disabled {\n  background-color: rgba(0, 36, 61, 0.5);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_OeDFA`,
	"buttonContainer": `buttonContainer_xvyEO`,
	"default": `default_sTEoH`,
	"error": `error_QNCGU`,
	"disabled": `disabled_S1kut`
};
export default ___CSS_LOADER_EXPORT___;
