// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay_vulYD {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup_ZuBVJ {
  background: white;
  padding: 20px 30px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.title_Gnfcr {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.subMessage_micI_ {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 20px;
}
.buttons_iALaY {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.yesButton_u7IS5 {
  background-color: rgb(0, 36, 61);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.noButton_BfMeV {
  background-color: white;
  color: 000;
  border: 1px solid rgb(0, 36, 61);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationPopup/ConfirmationPopup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;AAC5C;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,gCAAgC;EAChC,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,UAAU;EACV,gCAAgC;EAChC,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n.popup {\n  background: white;\n  padding: 20px 30px;\n  border-radius: 8px;\n  width: 400px;\n  text-align: center;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n}\n.title {\n  font-size: 18px;\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n.subMessage {\n  font-size: 14px;\n  color: #6c757d;\n  margin-bottom: 20px;\n}\n.buttons {\n  margin-top: 20px;\n  display: flex;\n  justify-content: space-between;\n}\n.yesButton {\n  background-color: rgb(0, 36, 61);\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 4px;\n  cursor: pointer;\n}\n.noButton {\n  background-color: white;\n  color: 000;\n  border: 1px solid rgb(0, 36, 61);\n  padding: 10px 20px;\n  border-radius: 4px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `overlay_vulYD`,
	"popup": `popup_ZuBVJ`,
	"title": `title_Gnfcr`,
	"subMessage": `subMessage_micI_`,
	"buttons": `buttons_iALaY`,
	"yesButton": `yesButton_u7IS5`,
	"noButton": `noButton_BfMeV`
};
export default ___CSS_LOADER_EXPORT___;
