// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_niVzS {
  color: #141414 !important;
  border-color: #878787 !important;
  font-family: "Maersk Text", sans-serif !important;
  font-style: normal !important;
  text-transform: none !important;
  height: 32px;
}

.button_niVzS:hover {
  border-color: #272727;
}
`, "",{"version":3,"sources":["webpack://./src/components/UsageReport/UsageReport.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,iDAAiD;EACjD,6BAA6B;EAC7B,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".button {\n  color: #141414 !important;\n  border-color: #878787 !important;\n  font-family: \"Maersk Text\", sans-serif !important;\n  font-style: normal !important;\n  text-transform: none !important;\n  height: 32px;\n}\n\n.button:hover {\n  border-color: #272727;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_niVzS`
};
export default ___CSS_LOADER_EXPORT___;
