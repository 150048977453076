// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartVertical_jKD16 {
  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);
  border-radius: 4px;
  padding: 4px 8px;
  height: 100%;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.16);
}

.chartHorizontal_OSzHs {
  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);
  border-radius: 4px;
  padding: 4px 8px;
  width: 100%;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.16);
  height: 100%;
}

.chartTitle_en7Sn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin: -4px -8px;
  height: 40px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  background: #f7f7f7;
  padding: 4px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chart/ChartCard/ChartCard.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;EAC3D,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,+CAA+C;AACjD;;AAEA;EACE,2DAA2D;EAC3D,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,+CAA+C;EAC/C,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".chartVertical {\n  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);\n  border-radius: 4px;\n  padding: 4px 8px;\n  height: 100%;\n  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.16);\n}\n\n.chartHorizontal {\n  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);\n  border-radius: 4px;\n  padding: 4px 8px;\n  width: 100%;\n  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.16);\n  height: 100%;\n}\n\n.chartTitle {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n  margin: -4px -8px;\n  height: 40px;\n  border-radius: 4px 4px 0 0;\n  display: flex;\n  align-items: center;\n  background: #f7f7f7;\n  padding: 4px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartVertical": `chartVertical_jKD16`,
	"chartHorizontal": `chartHorizontal_OSzHs`,
	"chartTitle": `chartTitle_en7Sn`
};
export default ___CSS_LOADER_EXPORT___;
