// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auditCtn_BzKj2 {
  padding: 14px 16px;
  border-radius: var(--mds_brand_border_small_radius);
  background: #fff;

  /* Shadow/High */
  box-shadow: var(--Shadow-High-Third-Offset-x, 0px)
      var(--Shadow-High-Third-Offset-y, 6px) var(--Shadow-High-Third-Blur, 10px)
      var(--Shadow-High-Third-Spread, 8px)
      var(--Shadow-High-Third-Color, rgba(0, 0, 0, 0.03)),
    var(--Shadow-High-Second-Offset-x, 0px)
      var(--Shadow-High-Second-Offset-y, 1px)
      var(--Shadow-High-Second-Blur, 18px) var(--Shadow-High-Second-Spread, 4px)
      var(--Shadow-High-Second-Color, rgba(0, 0, 0, 0.03)),
    var(--Shadow-High-First-Offset-x, 0px)
      var(--Shadow-High-First-Offset-y, 3px) var(--Shadow-High-First-Blur, 5px)
      var(--Shadow-High-First-Spread, -1px)
      var(--Shadow-High-First-Color, rgba(0, 0, 0, 0.04));
}

.status_efXwo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.gridItem_RqUT_ {
  display: flex;
  align-items: start;
  margin-bottom: 8px;
}

.label_nQjKh {
  min-width: 150px;
  font-weight: bold !important;
}

.colon_ZhnQo {
  margin-right: 20px !important;
}

.value_aVy4E {
  flex: 1;
}

.gridContainer_K_bM9 {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailsModal/DetailsModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mDAAmD;EACnD,gBAAgB;;EAEhB,gBAAgB;EAChB;;;;;;;;;;;yDAWuD;AACzD;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,aAAa;AACf","sourcesContent":[".auditCtn {\n  padding: 14px 16px;\n  border-radius: var(--mds_brand_border_small_radius);\n  background: #fff;\n\n  /* Shadow/High */\n  box-shadow: var(--Shadow-High-Third-Offset-x, 0px)\n      var(--Shadow-High-Third-Offset-y, 6px) var(--Shadow-High-Third-Blur, 10px)\n      var(--Shadow-High-Third-Spread, 8px)\n      var(--Shadow-High-Third-Color, rgba(0, 0, 0, 0.03)),\n    var(--Shadow-High-Second-Offset-x, 0px)\n      var(--Shadow-High-Second-Offset-y, 1px)\n      var(--Shadow-High-Second-Blur, 18px) var(--Shadow-High-Second-Spread, 4px)\n      var(--Shadow-High-Second-Color, rgba(0, 0, 0, 0.03)),\n    var(--Shadow-High-First-Offset-x, 0px)\n      var(--Shadow-High-First-Offset-y, 3px) var(--Shadow-High-First-Blur, 5px)\n      var(--Shadow-High-First-Spread, -1px)\n      var(--Shadow-High-First-Color, rgba(0, 0, 0, 0.04));\n}\n\n.status {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.gridItem {\n  display: flex;\n  align-items: start;\n  margin-bottom: 8px;\n}\n\n.label {\n  min-width: 150px;\n  font-weight: bold !important;\n}\n\n.colon {\n  margin-right: 20px !important;\n}\n\n.value {\n  flex: 1;\n}\n\n.gridContainer {\n  padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auditCtn": `auditCtn_BzKj2`,
	"status": `status_efXwo`,
	"gridItem": `gridItem_RqUT_`,
	"label": `label_nQjKh`,
	"colon": `colon_ZhnQo`,
	"value": `value_aVy4E`,
	"gridContainer": `gridContainer_K_bM9`
};
export default ___CSS_LOADER_EXPORT___;
