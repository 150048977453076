// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_wrYnk {
  display: flex;
  flex-direction: column;
}

.item_SIpsb {
  background: inherit;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);
  padding: 7px 0px 0px 13px;
}

.item_SIpsb img {
  margin-right: 13px;
}

.item_SIpsb:hover {
  background-color: #e2e2e2;
  cursor: pointer;
}

.content_k_fw_ {
  display: flex;
  align-items: flex-end;
}

.content_k_fw_ p {
  padding: 0 16px;
  font-size: 16px;
}

.svg_IhdYd {
  position: relative;
  display: inline-block;
}

.svg_IhdYd span {
  position: absolute;
  top: 33%;
  left: 40%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}

.black_mNsAI {
  color: var(--Color-Text-Neutral, #141414);
}

.white_LRbbB {
  color: var(--Color-On-background-Neutral, #e9e4e4);
}
`, "",{"version":3,"sources":["webpack://./src/components/RightTab/RightTab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kEAAkE;EAClE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,kDAAkD;AACpD","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n}\n\n.item {\n  background: inherit;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);\n  padding: 7px 0px 0px 13px;\n}\n\n.item img {\n  margin-right: 13px;\n}\n\n.item:hover {\n  background-color: #e2e2e2;\n  cursor: pointer;\n}\n\n.content {\n  display: flex;\n  align-items: flex-end;\n}\n\n.content p {\n  padding: 0 16px;\n  font-size: 16px;\n}\n\n.svg {\n  position: relative;\n  display: inline-block;\n}\n\n.svg span {\n  position: absolute;\n  top: 33%;\n  left: 40%;\n  transform: translate(-50%, -50%);\n  font-size: 14px;\n}\n\n.black {\n  color: var(--Color-Text-Neutral, #141414);\n}\n\n.white {\n  color: var(--Color-On-background-Neutral, #e9e4e4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_wrYnk`,
	"item": `item_SIpsb`,
	"content": `content_k_fw_`,
	"svg": `svg_IhdYd`,
	"black": `black_mNsAI`,
	"white": `white_LRbbB`
};
export default ___CSS_LOADER_EXPORT___;
