import React, { useEffect, useState } from 'react';
import TextField from "../Common/TextField/TextField";
import styles from './BaseBuild.css'
import { Button, LoadingIndicator } from '@anchor/react-components'
import Toast from '../../commonComponents/Toast/Toast';
import axiosInstance from '../../utility/axiosInstance';
import { baseBuildClasses, baseBuildTabData, CLASS_ONE, CLASS_TWO, DEVICE_ROLES } from '../../constants';
import RightTab from '../RightTab/RightTab';
import Dropdown from '../Common/Dropdown/Dropdown';
import MultiSelectDropdown from '../Common/MultiDropdown/MultiDropdown';

const BaseBuild = () => {
    const [values, setValues] = useState({ deviceRole: [], site: '', infraType: 'PHY', vlan: '', connectionName: '', siteInfo: [{ siteName: '', interface: '' }] })
    const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
    const [rightTabData, setRightTabData] = useState(baseBuildTabData)
    const [disable, setDisable] = useState(false)
    const [showDownload, setShowDownload] = useState(false)
    const [disableBtn, setDisableBtn] = useState(false)
    const [downloadData, setDownloadData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedClass, setSelectedClass] = useState(null)
    const [isClassSelected, setIsClassSelected] = useState(false)

    useEffect(() => {
        if (selectedClass === CLASS_ONE) {
            if (values.deviceRole.length > 0 && values.site && values.infraType) {
                setDisableBtn(false)
            } else {
                setDisableBtn(true)
            }
        } else if (selectedClass === CLASS_TWO) {
            if (values.vlan && values.connectionName && values.siteInfo.every(info => info.siteName && info.interface)) {
                setDisableBtn(false)
            } else {
                setDisableBtn(true)
            }
        }
    }, [values])

    const handleInputChange = (key, value) => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            setValues({ ...values, [key]: value.value })
        } else {
            setValues({ ...values, [key]: value })
        }
    }

    const handleDynamicInputChange = (group, index, key, value) => {
        setValues({ ...values, [group]: values[group].map((item, i) => i === index ? { ...item, [key]: value } : item) })
    }

    const handleSubmit = async () => {
        try {
            let api;
            let payload = {}
            setLoading(true)
            if (selectedClass === CLASS_ONE) {
                payload = {
                    device_roles: values.deviceRole,
                    site: values.site,
                    infra_type: values.infraType
                }
                api = `${process.env.API_GATEWAY}/base-build/base-build-config`
            } else {
                payload = {
                    vlan: values.vlan,
                    connection_name: values.connectionName,
                    site_info: values.siteInfo.map((item) => { return { site: item.siteName, interface: item.interface } })
                }
                api = `${process.env.API_GATEWAY}/base-build/base-build-config-connections`
            }
            const response = await axiosInstance.post(api, payload, {
                responseType: 'blob'
            })
            setDownloadData(response.data)
            setDisable(true)
            setDisableBtn(true)
            setShowDownload(true)
            setRightTabData(rightTabData.map((item, index) => ({
                ...item,
                completed: index <= 1,
                active: index === 2
            })))
            setShowToastObj({ show: true, type: 'success', message: 'Submitted Successfully' })
        } catch (e) {
            setShowToastObj({ show: true, type: 'error', message: e.message | 'Something went wrong' })
        } finally {
            setLoading(false)
        }

    }

    const downloadFile = async () => {
        try {
            const blob = new Blob([downloadData], { type: 'application/zip' })
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${values?.site || 'data'}.zip`)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
            setShowToastObj({ show: true, type: 'success', message: 'File downloaded' })
        } catch (e) {
            setShowToastObj({ show: true, type: 'error', message: e.message | 'Something went wrong' })
        }
    }

    const handleTabClick = ({ title, completed }, position) => {
        if (!completed) return;

        setRightTabData(rightTabData.map((data, index) => ({
            ...data,
            active: data.title === title,
            completed: data.completed && index < position
        })));

        const shouldResetValues = position <= 1;
        const shouldResetClass = position === 0;

        if (shouldResetValues) {
            setShowDownload(false)
            setDisable(false)
            setDisableBtn(false)
        };
        if (shouldResetClass) setIsClassSelected(false);
    };

    const handleSelectClass = () => {
        setIsClassSelected(true)
        setValues({ deviceRole: [], site: '', infraType: 'PHY', vlan: '', connectionName: '', siteInfo: [{ siteName: '', interface: '' }] })
        setRightTabData(rightTabData.map((item, index) => ({
            ...item,
            completed: index <= 0,
            active: index === 1
        })))
    }

    const handleAddDynamicGroup = () => {
        setValues({ ...values, siteInfo: [...values.siteInfo, { siteName: '', interface: '' }] })
    }

    const handleRemoveDynamicGroup = (index) => {
        setValues({ ...values, siteInfo: values.siteInfo.filter((item, i) => i !== index) })
    }

    return <>
        {loading ? <LoadingIndicator /> :
            <div className={styles.container}>
                <div className={`${styles.firstSection}`}>
                    <div>
                        <Dropdown
                            fit="medium"
                            id="classDropdown"
                            className={`${styles.dropdownField} }`}
                            errorMessage="This field can't be empty"
                            label={'Select base build class'}
                            name={'BaseBuild'}
                            onChange={(e) => setSelectedClass(e?.value || '')}
                            options={baseBuildClasses}
                            placeholder={`Select`}
                            required={true}
                            value={selectedClass}
                            disabled={isClassSelected}
                        />
                        {!isClassSelected && <Button
                            label="Next"
                            onClick={handleSelectClass}
                            disabled={!selectedClass}
                        />}
                    </div>
                    {(isClassSelected && selectedClass === CLASS_ONE) && <div className={styles.inputCtn}>
                        <MultiSelectDropdown
                            label={'Device Roles'}
                            className={`${styles.multiSelectDropdown}`}
                            placeholder={`Enter Device Roles`}
                            options={DEVICE_ROLES}
                            value={Array.isArray(values?.deviceRole) ? values?.deviceRole : []}
                            onChange={(e) => handleInputChange('deviceRole', e)}
                            disabled={disable}
                            required={true}
                        />
                        <TextField
                            fit="medium"
                            label="Site"
                            className={styles.inputField}
                            maxLength={10}
                            onChange={(e) => handleInputChange('site', e.target.value)}
                            placeholder="Enter Site"
                            required
                            type="text"
                            value={values.site}
                            variant="default"
                            disabled={disable}
                        />
                        <TextField
                            fit="medium"
                            label="Infra Type"
                            className={styles.inputField}
                            maxLength={10}
                            onChange={(e) => handleInputChange('infraType', e.target.value)}
                            placeholder="Enter Infra Type"
                            required
                            type="text"
                            value={values.infraType}
                            variant="default"
                            disabled={true}
                        />
                    </div>}
                    {(isClassSelected && selectedClass === CLASS_TWO) &&
                        <>
                            <div className={styles.inputCtn}>
                                <TextField
                                    fit="medium"
                                    label="Vlan"
                                    className={styles.inputField}
                                    maxLength={10}
                                    onChange={(e) => handleInputChange('vlan', e.target.value)}
                                    placeholder="Enter Vlan"
                                    required
                                    type="number"
                                    value={values.vlan}
                                    variant="default"
                                    disabled={disable}
                                />
                                <TextField
                                    fit="medium"
                                    label="Connection Name"
                                    className={styles.inputField}
                                    maxLength={10}
                                    onChange={(e) => handleInputChange('connectionName', e.target.value)}
                                    placeholder="Enter Connection Name"
                                    required
                                    type="text"
                                    value={values.connectionName}
                                    variant="default"
                                    disabled={disable}
                                />

                            </div>
                            <p className={styles.groupHeading}>Site Info</p>
                            <div className={styles.dynamicInputContainer}>
                                {values.siteInfo?.map((item, index) => {
                                    return <div className={styles.dynamicInputGroup} key={`${item.site}-${index}`}>
                                        <TextField
                                            fit="medium"
                                            label="Site"
                                            className={styles.inputField}
                                            maxLength={10}
                                            onChange={(e) => handleDynamicInputChange('siteInfo', index, 'siteName', e.target.value)}
                                            placeholder="Enter Site"
                                            required
                                            type="text"
                                            value={item.siteName}
                                            variant="default"
                                            disabled={disable}
                                        />
                                        <TextField
                                            fit="medium"
                                            label="Interface"
                                            className={styles.inputField}
                                            maxLength={10}
                                            onChange={(e) => handleDynamicInputChange('siteInfo', index, 'interface', e.target.value)}
                                            placeholder="Enter Interface"
                                            required
                                            type="text"
                                            value={item.interface}
                                            variant="default"
                                            disabled={disable}
                                        />
                                        {(index !== values.siteInfo?.length - 1 || index != 0) && <Button
                                            appearance="error"
                                            justifyItems="center"
                                            label="-"
                                            name="primary"
                                            className={styles.generateBtn}
                                            onClick={() => { handleRemoveDynamicGroup(index) }}
                                            title="-"
                                            variant="filled"
                                        />}
                                        {index === values.siteInfo?.length - 1 && <Button
                                            appearance="default"
                                            justifyItems="center"
                                            label="+"
                                            name="primary"
                                            className={styles.generateBtn}
                                            onClick={handleAddDynamicGroup}
                                            title="+"
                                            variant="filled"
                                            disabled={item.site === '' || item.interface === ''}
                                        />}
                                    </div>
                                })}
                            </div>
                        </>}
                    {(isClassSelected && !showDownload) && <Button
                        appearance="default"
                        justifyItems="center"
                        label="Submit"
                        name="primary"
                        className={styles.generateBtn}
                        onClick={handleSubmit}
                        title="Submit"
                        variant="filled"
                        disabled={disableBtn}
                    />}
                    {showDownload && <div className={styles.fileContainer}>
                        <p>Basebuild File</p>
                        <div className={styles.downloadContainer}>
                            <span>{`${values?.site || 'data'}.zip`}</span>
                            <button
                                className={` ${styles.downloadBtn} ${styles.btnStyles}`}
                                onClick={downloadFile}
                            >
                                <img src='/assets/downloadIcon.svg' alt="download-icon" />
                                <div className={styles.bulkText}>Download</div>
                            </button>
                        </div>
                    </div>}
                </div>
                <div className={styles.rightTabSection}>
                    <RightTab tabData={rightTabData} handleClick={handleTabClick} />
                </div>
            </div >
        }

        {
            showToastObj.show
                ? <Toast
                    showToast={showToastObj.show}
                    setShowToast={setShowToastObj}
                    message={showToastObj.message}
                    type={showToastObj.type} />
                : null
        }

    </>
}

export default BaseBuild;
