import React, { useEffect, useRef, useState } from 'react'
import styles from './MultiInput.css'
import PropTypes from 'prop-types'

export default function MultiInput({ label, placeholder, onChange, value, id, disabled, expandable = false, error, errorMessage, onBlur = () => { }, className }) {
  const [inputValue, setInputValue] = useState('')
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const dropdownRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setInputValue('')
        setDropdownVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (dropdownVisible && inputRef.current) {
      inputRef.current.focus()
      if (dropdownRef.current && typeof dropdownRef.current.scrollIntoView === 'function') {
        dropdownRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [dropdownVisible])

  const handleAddItem = (e) => {
    if (!disabled && e.key === 'Enter' && inputValue.trim() !== '') {
      const newItems = [...value, inputValue]
      onChange(newItems)
      setInputValue('')
      setDropdownVisible(false)
      onBlur(newItems)
    }
  }

  const handleRemoveItem = (index) => {
    if (!disabled) {
      const newItems = value.filter((_, i) => i !== index)
      onChange(newItems)
      onBlur(newItems)
    }
  }

  const toggleDropdown = () => {
    if (!disabled) {
      setInputValue('')
      setDropdownVisible(!dropdownVisible)
    }
  }

  return (
    <div className={`${styles.multiInputDropdown} ${disabled ? styles.disabled : ''} ${className}`} ref={dropdownRef} key={id}>
      <label htmlFor="multi-input">{label}</label>
      <button
        className={styles.inputBox}
        onClick={toggleDropdown}
        id="multi-input">
        {value.length === 0 && <span className={styles.placeholder}>{placeholder}</span>}

        {expandable ? value.map((item, index) => (
          <span key={`${index}-${item}`} className={styles.itemTag}>
            {item}
            {!disabled && <button onClick={() => handleRemoveItem(index)}>x</button>}
          </span>
        )) : value.slice(0, 2).map((item, index) => (
          <span key={`${index}-${item}`} className={styles.itemTag}>
            {item}
            {!disabled && <button onClick={() => handleRemoveItem(index)}>x</button>}
          </span>
        ))}
        {!expandable && value.length > 2 && <span>+{value.length - 2}</span>}
      </button>
      {error && <p className={styles.error}>{errorMessage}</p>}
      {
        dropdownVisible && !disabled && (
          <div className={styles.dropdown}>
            <div className={styles.dropdownItems}>
              {value.map((item, index) => (
                <div key={`${index}-${item}`} className={styles.dropdownItem}>
                  {item}
                  <button onClick={() => handleRemoveItem(index)}>x</button>
                </div>
              ))}
            </div>
            <input
              type="text"
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleAddItem}
              placeholder={placeholder}
              disabled={disabled}
            />
          </div>
        )
      }
    </div >
  )
};

MultiInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  expandable: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  className: PropTypes.string
}
