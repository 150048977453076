import React, { useEffect, useState } from 'react'
import styles from './OrderDetailsPage.css'
import { useDispatch, useSelector } from 'react-redux'
import ReviewModal from '../Review/Review.js'
import CommitModal from '../CommitModal/CommitModal.js'
import { fetchOrderDetails } from '../../redux/features/trackOrdersSlice.js'
import { useLocation } from 'react-router-dom'
import Toast from '../../commonComponents/Toast/Toast.js'
import { Button, Icon, LoadingIndicator } from '@anchor/react-components'
import { API_ERROR, APPROVE, APPROVED, COMMIT, DRY_RUN, FAILED, IN_PROGRESS, LOADING, PARTIAL, PENDING, REJECTED, REVIEW, SUCCESS, SUCCESS_STAGE } from '../../constants.js'
import axiosInstance from '../../utility/axiosInstance.js'
import RejectModal from '../RejectModal/RejectModal.js'

const OrderDetailsPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [intervalId, setIntervalId] = useState(null)
  const [rejectModal, setRejectModal] = useState(false)
  const [copied, setCopied] = useState(false)
  const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
  const [toggleExpand, setToggleExpand] = useState({ show: false })
  const orderDetails = useSelector((state) => state.trackOrders.orderDetails)
  const [orderDetailsObj, setOrderDetailsObj] = useState(orderDetails)
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [showCommitModal, setShowCommitModal] = useState(false)
  const [isReviewButtonDisabled, setIsReviewButtonDisabled] = useState(false)
  const [tooltip, setTooltip] = useState(null)
  const fetchOrderDetailsStatus = useSelector((state) => state.trackOrders.fetchOrderDetailsStatus)
  const searchParams = new URLSearchParams(location.search)
  const orderid = searchParams.get('orderid')

  useEffect(() => {
    setOrderDetailsObj(orderDetails)
    if ((orderDetails.current_stage === DRY_RUN || orderDetails.current_stage === COMMIT) &&
      orderDetails.current_status === IN_PROGRESS) {
      startPolling()
    }
    return () => clearInterval(intervalId)
  }, [orderDetails])

  useEffect(() => {
    dispatch(fetchOrderDetails(orderid))
  }, [orderid])

  const startPolling = () => {
    const currentStage = orderDetails.current_stage === DRY_RUN ? DRY_RUN : COMMIT
    const id = setInterval(async () => {
      try {
        const userId = sessionStorage.getItem('user_id');
        const response = await axiosInstance.post(`${process.env.API_GATEWAY}/check-stage-status?stage=${currentStage}&uuid=${orderDetails.order_id}&user_id=${userId}`)
        if (response.status === 200) {
          if (['Success', 'Failed'].includes(response.data.current_status)) {
            // failed , pending , success , skipped ,
            clearInterval(id) // Stop polling if status is 'done'
            dispatch(fetchOrderDetails(orderDetails.order_id))
          }
        }
      } catch (error) {
        clearInterval(id)
      }
    }, 3000) // Interval of 180 seconds
    setIntervalId(id) // Save interval ID in state
  }

  const getStatusApiDetails = (statusValue) => {
    return orderDetailsObj.status_arr?.find(status => status.value === statusValue)?.api_details
  }

  const constructPayload = (apiDetails, customPayload) => {
    let payload = null
    let queryParams = null

    if (apiDetails.request_body) {
      payload = { ...apiDetails.request_body, ...customPayload }
    }

    if (apiDetails.params) {
      queryParams = { ...apiDetails.params, ...customPayload }
    }

    return { payload, queryParams }
  }

  const handleApiCall = async (apiDetails, customPayload, successMessage, errorMessage, postSuccessAction) => {
    if (!apiDetails) return;
    const { payload, queryParams } = constructPayload(apiDetails, customPayload);
    const url = `${process.env.API_GATEWAY}${apiDetails.api}`;
    const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';

    try {
      if (apiDetails.method === 'POST') {
        await axiosInstance.post(`${url}${queryString}`, payload);
      } else if (apiDetails.method === 'GET') {
        const getQueryString = new URLSearchParams(queryParams || payload).toString();
        await axiosInstance.get(`${url}?${getQueryString}`);
      }

      if (postSuccessAction) postSuccessAction();

      setShowToastObj({ show: true, type: 'success', message: successMessage });
    } catch (error) {
      const message = error.response?.data?.message || errorMessage;
      setShowToastObj({ show: true, type: 'warning', message });
    }
  };

  const handleApprove = async () => {
    const apiDetails = getStatusApiDetails(APPROVE);
    if (!apiDetails) return;

    const customPayload = {
      uuid: orderDetailsObj.order_id,
      stage: APPROVE,
      status: APPROVED,
      user_id: sessionStorage.getItem('user_id'),
      description: ''
    };

    await handleApiCall(
      apiDetails,
      customPayload,
      'Order approved successfully',
      'Error approving order',
      () => dispatch(fetchOrderDetails(orderDetailsObj.order_id))
    );
  };

  const handleReject = async (note) => {
    const apiDetails = getStatusApiDetails(APPROVE);
    if (!apiDetails) return;

    const customPayload = {
      uuid: orderDetailsObj.order_id,
      stage: APPROVE,
      status: REJECTED,
      user_id: sessionStorage.getItem('user_id'),
      description: note
    };

    await handleApiCall(
      apiDetails,
      customPayload,
      'Order rejected successfully',
      'Error rejecting order',
      () => {
        setRejectModal(false);
        dispatch(fetchOrderDetails(orderDetailsObj.order_id));
      }
    );
  };

  const handleDryRun = async () => {
    const apiDetails = getStatusApiDetails(DRY_RUN);
    if (!apiDetails) return;

    const customPayload = {
      uuid: orderDetailsObj.order_id,
      path: orderDetailsObj.breadcrumb,
      dag_run_id: orderDetailsObj.order_id,
      note: DRY_RUN
    };

    await handleApiCall(
      apiDetails,
      customPayload,
      'Dryrun in-progress. Page will automatically refresh',
      'Error during dry run',
      () => dispatch(fetchOrderDetails(orderDetailsObj.order_id))
    );
  };

  const handleReview = async () => {
    const apiDetails = getStatusApiDetails(REVIEW);
    const customPayload = { uuid: orderDetailsObj.order_id, user_id: sessionStorage.getItem('user_id') };

    await handleApiCall(
      apiDetails,
      customPayload,
      'Review started',
      'Error starting review',
      () => setShowReviewModal(true)
    );
  };

  const handleCommit = async (note, referenceNumber) => {
    const apiDetails = getStatusApiDetails(COMMIT);
    const userId = sessionStorage.getItem('user_id');
    const customPayload = {
      uuid: orderDetailsObj.order_id,
      path: orderDetailsObj.breadcrumb,
      user: userId,
      reference_number: referenceNumber,
      note
    };

    await handleApiCall(
      apiDetails,
      customPayload,
      'Commit in-progress. Page will automatically refresh',
      'Error committing',
      () => {
        setShowCommitModal(false);
        dispatch(fetchOrderDetails(orderDetailsObj.order_id));
      }
    );
  };

  const setStatusColor = (status) => {
    let statusStyle
    switch (status) {
      case SUCCESS_STAGE:
      case APPROVED:
        statusStyle = styles.greenVal
        break
      case FAILED:
      case REJECTED:
        statusStyle = styles.redVal
        break
      case IN_PROGRESS:
        statusStyle = styles.orangeVal
        break
      case PENDING:
        statusStyle = styles.greyVal
        break
      default:
        statusStyle = styles.default
        break
    }
    return statusStyle
  }

  const handleOrderStageService = async (stage, status, description) => {
    try {
      const userId = sessionStorage.getItem('user_id');
      await axiosInstance.post(`${process.env.API_GATEWAY}/check-stage-status?uuid=${orderDetailsObj.order_id}&stage=${stage}&status=${status}&user_id=${userId}&description=${description}`)
      handleReviewModalClose()
      setShowToastObj({ show: true, type: 'success', message: status === 'Success' ? 'Review Confirmed' : 'Review Rejected' })
      dispatch(fetchOrderDetails(orderDetailsObj.order_id))
    } catch (err) {
      setShowToastObj({ show: true, type: 'warning', message: 'Review Failed' })
    }
  }

  const chunkArray = (array, size) => {
    const chunkedArr = []
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size))
    }
    return chunkedArr
  }
  const handleToggleExpand = (itemsWithOperation, index) => {
    const chunkedItems = chunkArray(itemsWithOperation.slice(3), 5)
    if (chunkedItems.length && chunkedItems[chunkedItems.length - 1].length < 5) {
      const lastChunk = chunkedItems[chunkedItems.length - 1]
      while (lastChunk.length < 5) {
        lastChunk.push({ title: '', value: '' })
      }
    }
    setToggleExpand({ show: !toggleExpand.show, data: chunkedItems, id: index })
  }

  const handleReviewModalClose = () => {
    setShowReviewModal(false)
    setIsReviewButtonDisabled(false)
  }

  const handleCommitModalClose = () => {
    setShowCommitModal(false)
  }

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const renderNextStepButton = () => {
    let _elem = null
    if (orderDetailsObj.current_stage === APPROVE && orderDetailsObj.actions_available.includes('Approve') && (orderDetailsObj.current_status === IN_PROGRESS || orderDetailsObj.current_status === PENDING)) {
      _elem = <>
        <Button
          className={styles.btn}
          appearance="default"
          icon="check-circle"
          id="primary"
          justifyItems="center"
          label="Approve"
          name="primary"
          onClick={() => handleApprove()}
          title="Approve"
          variant="filled"
        />
        <Button
          className={styles.btn}
          appearance="error"
          icon="check-circle"
          id="primary"
          justifyItems="center"clipboard-snowflake-text
          label="Reject"
          name="primary"
          onClick={() => setRejectModal(true)}
          title="Approve"
          variant="filled"
        />
      </>
    } else if (orderDetailsObj.current_stage === DRY_RUN && orderDetailsObj.current_status === PENDING && orderDetailsObj.actions_available.includes('Dryrun')) {
      _elem = <Button
        appearance="default"
        icon="cloud"
        id="primary"
        justifyItems="center"
        label="Dry Run"
        name="primary"
        onClick={() => handleDryRun()}
        title="Dry Run"
        variant="filled"
        className={styles.btn}
      />
    } else if (orderDetailsObj.current_stage === REVIEW && orderDetailsObj.current_status === IN_PROGRESS && orderDetailsObj.actions_available.includes('Review')) {
      _elem = <Button
        appearance="default"
        icon="file-eye"
        id="primary"
        justifyItems="center"
        label="Review"
        name="primary"
        onClick={() => handleReview()}
        title="Review"
        variant="filled"
        className={styles.btn}
      />
    } else if (orderDetailsObj.current_stage === COMMIT && orderDetailsObj.current_status === PENDING && orderDetailsObj.actions_available.includes('Commit')) {
      _elem = <Button
        appearance="default"
        icon="clipboard-snowflake-text"
        id="primary"
        justifyItems="center"
        label="Commit"
        name="primary"
        onClick={() => setShowCommitModal(true)}
        title="Commit"
        variant="filled"
        className={styles.btn}
      />
    } else if (orderDetailsObj.current_stage === COMMIT && orderDetailsObj.current_status === PARTIAL && orderDetailsObj.actions_available.includes('Commit')) {
      _elem = <Button
        appearance="default"
        icon="arrow-curved-up-right"
        id="primary"
        justifyItems="center"
        label="Redo"
        name="primary"
        onClick={() => setShowCommitModal(true)}
        title="Redo"
        variant="outlined"
        className={styles.btn}
      />
    }
    return _elem
  }

  function getTextColorClass(value) {
    if (value === 'Create') {
      return styles.greenText
    } else if (value === 'Modify') {
      return styles.orangeText
    } else if (value === 'Delete') {
      return styles.redText
    } else {
      return ''
    }
  }

  const renderOrderText = (item, i) => (
    <div className={styles.orderText} key={`${i}-${item.title}`}>
      <div className={styles.greyText}>{item.title}</div>
      <div className={styles.blacktext}>
        {Array.isArray(item.value) ? renderTooltip(item.value) : `${item.value}`}
      </div>
    </div>
  )

  const renderTooltip = (valueArray) => (
    <div className={styles.tooltip}>
      {valueArray[0]}
      {valueArray.length > 1 && (
        <>
          <span className={styles.tooltipText}>
            {valueArray.join(', ')}
          </span>
          <span>, {valueArray[1].slice(0, 3)}...</span>
        </>
      )}
    </div>
  )

  const renderChunk = (chunk, chunkIndex, index) => (
    <div
      className={`${styles.firstCtn} ${chunkIndex === toggleExpand.data.length - 1
        ? styles.bottomCtn
        : styles.bottomMargin
        }`}
      key={`${index}-${chunkIndex}`}
    >
      {chunk.map(renderOrderText)}
      {renderChevron(chunkIndex, index)}
    </div>
  )

  const renderChevron = (chunkIndex, index) => {
    if (chunkIndex === toggleExpand.data.length - 1 && index === toggleExpand.id) {
      return (
        <div className={styles.chevronContainer}>
          <button
            className={styles.chevron}
            onClick={() => setToggleExpand({ show: !toggleExpand.show })}
          >
            <img src='/assets/upChevron.svg' alt="upChevron" />
          </button>
        </div>
      )
    }
    return null
  }

  const renderIcon = (stage) => {
    if (stage === 'submit') return <Icon className={styles.buttonIcon} name="check-circle" />
    if (stage === 'approve') return <Icon className={styles.buttonIcon} name="file-check" />
    if (stage === 'dryrun') return <Icon className={styles.buttonIcon} name="cloud" />
    if (stage === 'review') return <Icon className={styles.buttonIcon} name="file-eye" />
    if (stage === 'commit') return <Icon className={styles.buttonIcon} name="clipboard-snowflake-text" />
  }

  const renderChunks = (chunks, index) => (
    chunks.map((chunk, chunkIndex) => renderChunk(chunk, chunkIndex, index))
  )


  const showTooltip = (index) => {
    if (tooltip && tooltip.index === index) return setTooltip(null)
    const currentItem = orderDetailsObj?.status_arr[index]
    const stageDetails = orderDetails?.stages_info?.[currentItem?.value]
    setTooltip({ ...stageDetails, index })
  }

  const renderContent = () => {
    if (fetchOrderDetailsStatus === LOADING) {
      return <div className={styles.loader}>
        <LoadingIndicator />
      </div>
    } else if (fetchOrderDetailsStatus === API_ERROR) {
      return <div className={styles.apiError}>Error fetching data</div>
    } else if (Object.keys(orderDetailsObj).length === 0) {
      return <div className={styles.noSites}>No Data Found</div>
    } else if (fetchOrderDetailsStatus === SUCCESS) {
      return <div className={styles.orderDetailsBody}>
        <div className={styles.firstCtn}>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Placed On</div>
            <div className={styles.blacktext}>{orderDetailsObj.order_placed_on}</div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Placed By</div>
            <div className={styles.blacktext}> {orderDetailsObj.order_placed_by}</div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Id </div>

            <div className={`${styles.blacktext} `}>
              <span className={styles.tooltip}>{orderDetailsObj.order_id.slice(0, 10)}...
                <span className={styles.tooltipText}>
                  {orderDetailsObj.order_id}
                </span></span>
              <button className={`${styles.copyButton} ${styles.tooltip}`} onClick={() => copyToClipBoard(orderDetailsObj.order_id)}>
                <span className={styles.tooltipText}>{copied ? '✓ Copied to clipboard' : 'Copy to clipboard'}</span>
                <Icon className={styles.copyIcon} name='copy' />
              </button>
            </div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Stage</div>
            <div className={styles.blacktext}> {orderDetailsObj?.current_stage}</div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Status</div>
            <div className={`${setStatusColor(orderDetailsObj?.current_status)} ${styles.default} ${styles.statusButton}`}>{orderDetailsObj?.current_status}</div>
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.orderStageStatusCtn}>
          <div className={styles.stageOuter}>
            {orderDetailsObj?.status_arr.map((item, index) => {
              return <React.Fragment key={`${index}-${item.title}-${item.value}`}>
                <div className={`${styles.stage} ${styles.tooltip}`}>
                  <div className={`${setStatusColor(item.state)} ${styles.default} ${styles.iconWrapper}`} >
                    {renderIcon(item.value)}
                    {item.title}
                  </div>
                  {![PENDING, IN_PROGRESS].includes(item.state) &&
                    <Icon name='info-circle' className={`${styles.infoIcon}`} onClick={() => showTooltip(index)} />}
                  {tooltip && tooltip.index === index && (
                    <div className={styles.notificationBox}>
                      <div className={styles.notificationContent}>
                        <div>
                          <p className={styles.message}>{`${item.value === 'approve' ? item?.state : item?.title} by ${tooltip?.updated_by_user}`}</p>
                          {item.value === 'review' ?
                            <Button
                              appearance="default"
                              id="primary"
                              justifyItems="left"
                              label="Show Review Output"
                              name="primary"
                              onClick={() => {
                                setIsReviewButtonDisabled(true)
                                setShowReviewModal(true)
                              }}
                              variant="outlined"
                            /> : <p className={styles.message}>{tooltip?.description}</p>}
                        </div>
                        <div className={styles.actions}>
                          {/* <button className={styles.copyBtn} >
                          <Icon className={styles.icon} name='copy' />
                        </button> */}
                          <button className={styles.closeBtn} onClick={() => setTooltip(null)}>
                            <Icon className={styles.icon} name='times' />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {index !== orderDetailsObj.status_arr.length - 1 && <div className={styles.horizontalLine}></div>}
              </React.Fragment>
            })}
          </div>
          <div className={styles.nextStep}>{renderNextStepButton()}</div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.orderDetailsObj}>Ordered Items</div>
        <div >
          {orderDetailsObj?.all_actions?.map((detail, index) => {
            const items = Object.values(detail).sort((a, b) => a?.title?.localeCompare(b?.title))
            const operationItem = items?.find(item => item?.title === 'Operation')
            const itemsWithOperation = items?.filter(item => item?.title !== 'Operation')
            while (itemsWithOperation?.length < 3) {
              itemsWithOperation.push({ title: '', value: '' })
            }
            return (
              <div key={`${index}-${detail?._action_?.url}`}>
                <div className={`${styles.firstCtn} ${index !== toggleExpand?.id ? styles.bottomCtn : styles.bottomMargin}`} >
                  {operationItem && (
                    <div className={styles.orderText} key={`Operation-${index}-${detail?._action_?.url}`}>
                      <div className={styles.greyText}>{operationItem?.title}</div>
                      <div className={`${styles.blacktext} ${getTextColorClass(operationItem?.value)}`}>{operationItem.value}</div>
                    </div>
                  )}
                  <div className={styles.orderText} key={`first-${index}-${detail?._action_?.url}`}>
                    <div className={styles.greyText}>Url</div>
                    <div className={`${styles.blacktext} ${styles.tooltip}`}>
                      {detail?._action_?.url.split('/')[0]} /
                      {detail?._action_?.url.split('/')?.length > 1 && (
                        <>
                          <span className={styles.tooltipText}>
                            {detail?._action_?.url.split('/')?.join('/')}
                          </span>
                          <span>{detail?._action_?.url?.split('/')[1]?.slice(0, 5)}...</span>
                        </>
                      )}
                    </div>
                  </div>
                  {itemsWithOperation?.slice(0, 3)?.map((item, i) => {
                    return (
                      <div className={styles.orderText} key={`item-${i}-${item?.title}`}>
                        <div className={styles.greyText}>{item?.title}</div>
                        <div className={styles.blacktext}>
                          {Array.isArray(item?.value)
                            ? (
                              <div className={styles.tooltip}>
                                {item?.value[0]}
                                {item?.value?.length > 1 && (
                                  <>
                                    <span className={styles.tooltipText}>
                                      {item?.value?.join(', ')}
                                    </span>
                                    <span>, {item?.value[1]?.slice(0, 3)}...</span>
                                  </>
                                )}
                              </div>
                            )
                            : (
                              `${item?.value}`
                            )}
                        </div>
                      </div>
                    )
                  })}
                  <div className={styles.chevronContainer}>
                    {itemsWithOperation?.length > 3 && index !== toggleExpand.id && (
                      <button className={styles.chevron} onClick={() => handleToggleExpand(itemsWithOperation, index)}>
                        <img src='/assets/downChevron.svg' alt="downChevron" />
                      </button>
                    )}
                  </div>
                </div>
                {toggleExpand?.show && index === toggleExpand?.id && renderChunks(toggleExpand?.data, index)}
              </div>
            )
          })}
        </div>
      </div >
    }
  }

  return <div className={styles.orderDetailsCtn}>
    <div className={styles.orderDetailsTitle}>Order Details</div>
    <RejectModal rejectModal={rejectModal} handleRejectModalClose={() => setRejectModal(false)} handleReject={handleReject} />
    {renderContent()}
    {showReviewModal && <ReviewModal showModal={showReviewModal} handleModalClose={handleReviewModalClose} handleOrderStageService={handleOrderStageService} isDisable={isReviewButtonDisabled} />}
    {showCommitModal && <CommitModal commitModal={showCommitModal} handleModalClose={handleCommitModalClose} handleCommit={handleCommit} />}
    {showToastObj.show &&
      <Toast
        showToast={showToastObj.show}
        setShowToast={setShowToastObj}
        message={showToastObj.message}
        type={showToastObj.type} />
    }
  </div>
}
export default OrderDetailsPage