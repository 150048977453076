// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-grid_wvyRk{
    padding: 0 15px 15px;
    border:1px solid #DBDBDB;
    border-radius: 3px;
}
.data-grid-header_JBS1U{
    height: 40px;
    background-color: #f7f7f7;
    margin: 0 -15px 15px;
    padding: 0 15px;
}
.dataGridSize_AUkJr{
   height:100%;
}`, "",{"version":3,"sources":["webpack://./src/components/DataGrid/DataGrid.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,wBAAwB;IACxB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,oBAAoB;IACpB,eAAe;AACnB;AACA;GACG,WAAW;AACd","sourcesContent":[".data-grid{\n    padding: 0 15px 15px;\n    border:1px solid #DBDBDB;\n    border-radius: 3px;\n}\n.data-grid-header{\n    height: 40px;\n    background-color: #f7f7f7;\n    margin: 0 -15px 15px;\n    padding: 0 15px;\n}\n.dataGridSize{\n   height:100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"data-grid": `data-grid_wvyRk`,
	"data-grid-header": `data-grid-header_JBS1U`,
	"dataGridSize": `dataGridSize_AUkJr`
};
export default ___CSS_LOADER_EXPORT___;
