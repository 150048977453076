// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdownField_VasdJ {
  flex: 1;
  margin-right: 16px;
  /* Adjust as needed */
  margin-bottom: 24px;
  width: 390px;
}

.dropdownField_VasdJ:last-child {
  margin-right: 0;
}

.inputField_E4jgh {
  flex: 1;
  margin-right: 16px;
  /* Adjust as needed */
  margin-bottom: 6px;
}

.inputField_E4jgh:last-child {
  margin-right: 0;
}

.multiSelectDropdown_XJaMQ {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  min-height: 70px;
}

.heading_Enepp {
  font-size: 32px;
  font-weight: 400;
}

.container_WMcSE {
  display: flex;
  justify-content: space-between;
}

.firstSection_IwrAi {
  width: 70%;
}

.prefixInput_ARMcL {
  padding-bottom: 1.5%;
}

.detailsSection_Aeiz7 {
  padding-top: 2%;
  margin-right: 20px;
  border-top: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);
  padding-bottom: 1.5%;
}

.insideContainer_bQoKy {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0px 20px;
  margin-bottom: 6px;
}

.rightTabSection_nztTf {
  height: calc(100vh - 242px);
  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 4px 3px;
  width: 30%;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/WarehouseProvision/WarehouseProvision.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,OAAO;EACP,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,+DAA+D;EAC/D,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,gDAAgD;EAChD,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,2DAA2D;EAC3D,+CAA+C;EAC/C,UAAU;EACV,cAAc;AAChB","sourcesContent":[".dropdownField {\n  flex: 1;\n  margin-right: 16px;\n  /* Adjust as needed */\n  margin-bottom: 24px;\n  width: 390px;\n}\n\n.dropdownField:last-child {\n  margin-right: 0;\n}\n\n.inputField {\n  flex: 1;\n  margin-right: 16px;\n  /* Adjust as needed */\n  margin-bottom: 6px;\n}\n\n.inputField:last-child {\n  margin-right: 0;\n}\n\n.multiSelectDropdown {\n  position: relative;\n  display: inline-block;\n  width: 100%;\n  margin-bottom: 6px;\n  min-height: 70px;\n}\n\n.heading {\n  font-size: 32px;\n  font-weight: 400;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.firstSection {\n  width: 70%;\n}\n\n.prefixInput {\n  padding-bottom: 1.5%;\n}\n\n.detailsSection {\n  padding-top: 2%;\n  margin-right: 20px;\n  border-top: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);\n  padding-bottom: 1.5%;\n}\n\n.insideContainer {\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap: 0px 20px;\n  margin-bottom: 6px;\n}\n\n.rightTabSection {\n  height: calc(100vh - 242px);\n  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);\n  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 4px 3px;\n  width: 30%;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownField": `dropdownField_VasdJ`,
	"inputField": `inputField_E4jgh`,
	"multiSelectDropdown": `multiSelectDropdown_XJaMQ`,
	"heading": `heading_Enepp`,
	"container": `container_WMcSE`,
	"firstSection": `firstSection_IwrAi`,
	"prefixInput": `prefixInput_ARMcL`,
	"detailsSection": `detailsSection_Aeiz7`,
	"insideContainer": `insideContainer_bQoKy`,
	"rightTabSection": `rightTabSection_nztTf`
};
export default ___CSS_LOADER_EXPORT___;
