// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderCtn_ulNX3 {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.loader_HMA97 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container_mBjtN {
  display: flex;
  border: 1px solid var(--Color-Border-Neutral, #cfcfcf);
  height: 100%;
  box-shadow: 0px 6px 10px 8px rgba(0, 0, 0, 0.03),
    0px 1px 18px 4px rgba(0, 0, 0, 0.03), 0px 3px 5px -1px rgba(0, 0, 0, 0.04);
}
`, "",{"version":3,"sources":["webpack://./src/components/SitePage/SitePage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sDAAsD;EACtD,YAAY;EACZ;8EAC4E;AAC9E","sourcesContent":[".loaderCtn {\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n.loader {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.container {\n  display: flex;\n  border: 1px solid var(--Color-Border-Neutral, #cfcfcf);\n  height: 100%;\n  box-shadow: 0px 6px 10px 8px rgba(0, 0, 0, 0.03),\n    0px 1px 18px 4px rgba(0, 0, 0, 0.03), 0px 3px 5px -1px rgba(0, 0, 0, 0.04);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderCtn": `loaderCtn_ulNX3`,
	"loader": `loader_HMA97`,
	"container": `container_mBjtN`
};
export default ___CSS_LOADER_EXPORT___;
