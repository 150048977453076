// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading_xTTE2 {
  font-size: 32px;
  font-weight: 400;
}

.container_wJ0K_ {
  display: flex;
  justify-content: space-between;
}

.firstSection_NLMvd {
  width: 35%;
}

.dropdownField_qair4 {
  flex: 1;
  margin-right: 16px;
  /* Adjust as needed */
  margin-bottom: 16px;
  width: 100%;
}

.dropdownField_qair4:last-child {
  margin-right: 0;
}

.sections_IPOnY {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0px 20px;
  margin-top: 16px;
}

.detailsSection_fhRdx {
  padding-top: 2%;
  margin-right: 20px;
  /* border-top: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb); */
  padding-bottom: 1.5%;
  margin-top: 16px;
}

.insideContainer_fgCH6 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0px 20px;
  margin-bottom: 6px;
  align-items: center;
}

.inputField_Ofef7 {
  flex: 1;
  margin-right: 16px;
  /* Adjust as needed */
  margin-bottom: 6px;
}

.inputField_Ofef7:last-child {
  margin-right: 0;
}

.radioGroup_UK2LK {
  margin-bottom: 18px;
}

.dateContainer_tLmiu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.fromDate_yQ2Cd {
  margin-right: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AegisBackup/AegisBackup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,OAAO;EACP,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gDAAgD;EAChD,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,qEAAqE;EACrE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gDAAgD;EAChD,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,OAAO;EACP,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".heading {\n  font-size: 32px;\n  font-weight: 400;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.firstSection {\n  width: 35%;\n}\n\n.dropdownField {\n  flex: 1;\n  margin-right: 16px;\n  /* Adjust as needed */\n  margin-bottom: 16px;\n  width: 100%;\n}\n\n.dropdownField:last-child {\n  margin-right: 0;\n}\n\n.sections {\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap: 0px 20px;\n  margin-top: 16px;\n}\n\n.detailsSection {\n  padding-top: 2%;\n  margin-right: 20px;\n  /* border-top: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb); */\n  padding-bottom: 1.5%;\n  margin-top: 16px;\n}\n\n.insideContainer {\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap: 0px 20px;\n  margin-bottom: 6px;\n  align-items: center;\n}\n\n.inputField {\n  flex: 1;\n  margin-right: 16px;\n  /* Adjust as needed */\n  margin-bottom: 6px;\n}\n\n.inputField:last-child {\n  margin-right: 0;\n}\n\n.radioGroup {\n  margin-bottom: 18px;\n}\n\n.dateContainer {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 18px;\n}\n\n.fromDate {\n  margin-right: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `heading_xTTE2`,
	"container": `container_wJ0K_`,
	"firstSection": `firstSection_NLMvd`,
	"dropdownField": `dropdownField_qair4`,
	"sections": `sections_IPOnY`,
	"detailsSection": `detailsSection_fhRdx`,
	"insideContainer": `insideContainer_fgCH6`,
	"inputField": `inputField_Ofef7`,
	"radioGroup": `radioGroup_UK2LK`,
	"dateContainer": `dateContainer_tLmiu`,
	"fromDate": `fromDate_yQ2Cd`
};
export default ___CSS_LOADER_EXPORT___;
