// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customLegend_kh1kb {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  flex-direction: column;
  height: fit-content;
}

.legendItem_qdOLo {
  display: flex;
  align-items: center;
  margin: 5px 10px;
  font-size: 14px;
  line-height: 24px;
}

.legendColor_fNk4e {
  width: 20px;
  height: 6px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 10px;
}

.chartVertical_RmxUM {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chartHorizontal_WYS1A {
  display: flex;
  align-items: center;
}

.chartHorizontal_WYS1A .chartContainer_RPSn4 {
  width: 90%;
}

.chartHorizontalPie_rfmae {
  display: flex;
}

.chartHorizontalPie_rfmae .chartContainer_RPSn4 {
  width: 55%;
}

.chartVertical_RmxUM .chartContainer_RPSn4 {
  width: 100%;
}

.pieChart_HrshL {
  width: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chart/Chart/Chart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".customLegend {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  border: 1px solid #ddd;\n  padding: 10px;\n  margin-top: 10px;\n  border-radius: 5px;\n  flex-direction: column;\n  height: fit-content;\n}\n\n.legendItem {\n  display: flex;\n  align-items: center;\n  margin: 5px 10px;\n  font-size: 14px;\n  line-height: 24px;\n}\n\n.legendColor {\n  width: 20px;\n  height: 6px;\n  display: inline-block;\n  margin-right: 10px;\n  border-radius: 10px;\n}\n\n.chartVertical {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.chartHorizontal {\n  display: flex;\n  align-items: center;\n}\n\n.chartHorizontal .chartContainer {\n  width: 90%;\n}\n\n.chartHorizontalPie {\n  display: flex;\n}\n\n.chartHorizontalPie .chartContainer {\n  width: 55%;\n}\n\n.chartVertical .chartContainer {\n  width: 100%;\n}\n\n.pieChart {\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customLegend": `customLegend_kh1kb`,
	"legendItem": `legendItem_qdOLo`,
	"legendColor": `legendColor_fNk4e`,
	"chartVertical": `chartVertical_RmxUM`,
	"chartHorizontal": `chartHorizontal_WYS1A`,
	"chartContainer": `chartContainer_RPSn4`,
	"chartHorizontalPie": `chartHorizontalPie_rfmae`,
	"pieChart": `pieChart_HrshL`
};
export default ___CSS_LOADER_EXPORT___;
