// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contextmenuCtn_B62fj{
    padding: 0 14px;
}
.contxtMenuItem_l901Z{
    color: var(--mds_brand_appearance_error_default_text-color);

/* Desktop/Text small/Regular */
font-family: "Maersk Text", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}`, "",{"version":3,"sources":["webpack://./src/components/DataGrid/ContextMenu/ContextMenu.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,2DAA2D;;AAE/D,+BAA+B;AAC/B,sCAAsC;AACtC,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,aAAa;AAChC","sourcesContent":[".contextmenuCtn{\n    padding: 0 14px;\n}\n.contxtMenuItem{\n    color: var(--mds_brand_appearance_error_default_text-color);\n\n/* Desktop/Text small/Regular */\nfont-family: \"Maersk Text\", sans-serif;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 20px; /* 142.857% */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextmenuCtn": `contextmenuCtn_B62fj`,
	"contxtMenuItem": `contxtMenuItem_l901Z`
};
export default ___CSS_LOADER_EXPORT___;
